<template>
  <div>
   <app-redirect/>
  </div>
</template>


<script>

import appRedirect from "@/pages/components/appRedirect";
  export default {
    name: "src-pages-body-workstation-favorites-v2_mobile",
    components:{appRedirect},
    data() {
      return {
      }
    },
  }

</script>

